export const user_professions = {
  get: {
    url: "admin/profession/get/",
    method: "GET",
    id: null,
    params: null,
  },
  delete: {
    url: "admin/profession/delete/",
    method: "DELETE",
  },
  create: {
    url: "profession/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "admin/profession/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  sub_profession: {
    get: {
      url: "subprofession/get",
      method: "GET",
      params: null,
    },
    create: {
      url: "subprofession/create",
      method: "POST",
      data: null,
    },
    update: {
      url: "admin/subprofession/update/",
      method: "PUT",
      id: null,
      data: null,
    },
    delete: {
      url: "admin/subprofession/delete/",
      method: "DELETE",
    },
  },
};
