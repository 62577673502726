<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { user_professions } from "@/config/api/user_professions";

export default {
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "USER PROFESSIONS",
            items: [
                {
                    text: "All",
                },
                {
                    text: "USER PROFESSIONS",
                    active: true,
                },
            ],
            professionList: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 100,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: false, label: "Name EN", thStyle: { width: "30%" } },
                { key: "name_ar", sortable: false, label: "Name AR", thStyle: { width: "30%" } },
               
                { key: "actions", sortable: false },
            ],
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.totalRows;
        },
    },
    mounted() {
        // Set the initial number of items
        this.loadData();
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered() {
            // Trigger pagination to update the number of buttons/pages due to filtering
            // this.totalRows = filteredItems.length;
            // this.currentPage = 1;
        },
        updatePage(page) {
            this.loadData(page);
        },
        loadData(page = 1, search = "") {
            const api = user_professions.get;
            api.params = { page: page, search: search, limit: 100 };
            this.generateAPI(api).then((res) => {
                this.professionList = res.data.professions;
                // this.totalRows = res.data.property_types.totalDocs;
            });
        },
        deleteItem(id) {
            let api = { ...user_professions.delete };
            api.url += id;
            this.generateAPI(api)
                .then((res) => {
                    this.loadData();
                    console.log(res);
                    this.$bvToast.toast("User Professions has been deleted!", {
                        title: "Create",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((err) => {
                    this.$bvToast.toast("Could not delete User Professions!", {
                        title: "Create",
                        variant: "danger",
                        solid: true,
                    });
                    console.log(err);
                });
        },
        onRowClicked(item) {
            this.$router.push({
                path: "/sub-user-professions",
                query: { id: item._id, item: item },
            });
        },


    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0">
                        <b-button id="addProperty" @click="
                          $router.push({
                            path: `edit-user-professions`,
                          })
                        " pill variant="primary">Add Profession</b-button>

                        <b-tabs nav-class="nav-tabs-custom">
                            <b-tab title-link-class="p-3">
                                <template v-slot:title>
                                    <a class="font-weight-bold active">All User Professions</a>
                                </template>
                                <div class="row mt-4">
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_length" class="dataTables_length">
                                            <label class="d-inline-flex align-items-center">
                                                Show&nbsp;
                                                <b-form-select v-model="perPage" size="sm" :options="pageOptions">
                                                </b-form-select>&nbsp;entries
                                            </label>
                                        </div>
                                    </div>
                                    <!-- Search -->
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                            <label class="d-inline-flex align-items-center">
                                                Search:
                                                <b-form-input v-model="filter" type="search"
                                                    class="form-control form-control-sm ml-2">
                                                </b-form-input>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- End search -->
                                </div>

                                <div class="table-responsive">
                                    <b-table class="table-centered" :items="professionList" :fields="fields"
                                        responsive="sm" :busy="$store.state.api.loading" :current-page="currentPage"
                                        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                                        :filter-included-fields="filterOn" @filtered="onFiltered"
                                        @row-clicked="onRowClicked" show-empty>
                                        <template #table-busy>
                                            <div style="text-align: center" class="mt-5 mb-5">
                                                <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                                            </div>
                                        </template>

                                        <template v-slot:cell(name)="row">
                                            {{ row.value }}
                                        </template>

                                        <template #cell(active)="row">
                                            {{ row.item.deletedAt ? "Deleted" : "Active" }}
                                        </template>
                                        <template #cell(actions)="row">
                                            <button class="action-btn" @click="deleteItem(row.item._id)">
                                                <i class="ri-delete-bin-line"></i>
                                            </button>
                                            <button class="action-btn" @click="
                                              $router.push({
                                                path: `edit-user-professions`,
                                                query: { id: row.item._id},
                                              })
                                            ">
                                                <i class="ri-edit-line"></i>
                                            </button>
                                        </template>
                                    </b-table>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <b-pagination v-model="currentPage" :total-rows="rows"
                                                    :per-page="perPage" @change="updatePage">
                                                </b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style scoped>
#addProperty {
    float: right;
    margin-top: 10px;
}
</style>


